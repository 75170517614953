import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";

import { SIGN_IN_URL, SIGN_UP_URL, ASSET_MANAGER_URL, DASHBOARD_URL } from "../constants/pathConstants";
import { DEMO_USER } from "../constants/demoUserConstants";

import { AuthContext } from "../context/AuthContext";

const Welcome = () => {
  const { setUser } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleRunDemo = () => {
    setUser(DEMO_USER);
    navigate(ASSET_MANAGER_URL + DASHBOARD_URL);
  }

  
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "rgb(246,246,246)",
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      }}
    >
      <div
        style={{
          backgroundColor: "#ffffff", 
          borderRadius: "15px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          padding: "40px 30px",
          textAlign: "center",
          maxWidth: "500px",
          width: "90%",
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/assets/DPA.png`}
          alt="Logo"
          style={{
            height: "100px",
            width: "250px",
            marginBottom: "20px",
            backgroundColor: "#ffffff", 
            borderRadius: "10px",
          }}
        />
  
        <h1
          style={{
            color: "#007bff",
            fontSize: "2rem",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Asset Management System
        </h1>
  
        <p
          style={{
            color: "#6c757d",
            fontSize: "1.2rem",
            fontWeight: "normal",
            marginBottom: "30px",
          }}
        >
          Manage your assets efficiently and effectively.
        </p>
  
        <div style={{ marginBottom: "20px" }}>
          <Link
            to={SIGN_IN_URL}
            style={{
              textDecoration: "none",
              color: "#ffffff",
              backgroundColor: "#007bff",
              padding: "10px 20px",
              borderRadius: "5px",
              marginRight: "10px",
              fontWeight: "bold",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#007bff")}
          >
            Sign In
          </Link>
          <Link
            to={SIGN_UP_URL}
            style={{
              textDecoration: "none",
              color: "#ffffff",
              backgroundColor: "#28a745",
              padding: "10px 20px",
              borderRadius: "5px",
              fontWeight: "bold",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#1e7e34")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#28a745")}
          >
            Sign Up
          </Link>
        </div>
  
        <button
          onClick={handleRunDemo}
          style={{
            marginTop: "20px",
            padding: "12px 25px",
            borderRadius: "8px",
            background: "linear-gradient(45deg, #17a2b8, #138496)",
            color: "#fff",
            border: "none",
            fontSize: "1rem",
            fontWeight: "bold",
            cursor: "pointer",
            transition: "transform 0.2s ease, box-shadow 0.2s ease",
          }}
          onMouseOver={(e) => {
            e.target.style.transform = "scale(1.05)";
            e.target.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.2)";
          }}
          onMouseOut={(e) => {
            e.target.style.transform = "scale(1)";
            e.target.style.boxShadow = "none";
          }}
        >
          Try Demo Mode
        </button>
      </div>
    </div>
  );
}

export default Welcome;